import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { MAIN_PAGE_IMAGE } from 'constants/images';

interface OuterImageDivProps {
  url?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  inView?: boolean; // used below but eslint doesn't seem to notice
  children?: React.ReactNode;
}

const OuterImageDiv = styled.div`
  width: 100vw;
  z-index: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  min-height: 700px; // the min height that the logo and details look good and don't overlap the navbar

  background-color: #fafafa;
`;

const ImageBackgroundDiv = styled.div<OuterImageDivProps>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1; // so that everything sits on top
  min-height: 700px; // the min height that the logo and details look good and don't overlap the navbar

  ${({ inView }) => `opacity: ${inView ? 1 : 0};`}
  background-image: url(${(props) => props.url || MAIN_PAGE_IMAGE});
  transition: opacity 1000ms linear;
  background-size: cover;
  background-position: center;

  // Adding blur and brightness filters
  // filter: blur(0.6px); // Adjust blur and brightness values as needed

  // filter: brightness(1.05); // Adjust values as needed

  // Adding a white overlay to make the background whiter
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2); // Adjust white overlay opacity as needed
    z-index: -1;
  }
`;

const MainImageDiv = ({ url, children }: OuterImageDivProps) => {
  const { inView, ref } = useInView({ triggerOnce: true });

  return (
    <OuterImageDiv ref={ref}>
      <ImageBackgroundDiv url={url} inView={inView} />
      {children}
    </OuterImageDiv>
  );
};

export default MainImageDiv;
